<template>
  <div class="viewarticle" v-loading.fullscreen.lock="loading">
    <div class="articletitle">
      <h2 class="viewtitle">{{ article.title }}</h2>
      <div style="margin: 10px 0; font-size: 13px; text-align: center">
        <span style="color: #737373">
          <i class="el-icon-date" style="margin-right: 5px"></i>
          {{ "发布于：" + this.common.dateFormatter(article.insertTime) }}
        </span>
        <span style="color: #737373; margin: 5px 20px">
          <i class="el-icon-postcard" style="margin-right: 5px"></i>
          {{ article.type === 0 ? "日常杂记" : "技术博文" }}
        </span>
        <span style="color: #737373; margin: 0 5px">
          <i class="el-icon-milk-tea" style="margin-right: 5px"></i>
          {{ "共计：" + article.size + "字" }}
        </span>
        <hr class="layui-bg-blue" />
      </div>
    </div>
    <div v-html="article.content"></div>
    <div
      onselectstart="return false"
      style="text-align: center; border-bottom: 1px dashed orange"
    >
      <div
        class="zan"
        style="
          font-weight: 700;
          display: inline-block;
          margin: 25px;
          padding: 8px;
          width: 90px;
          font-size: 25px;
          border-radius: 20px;
          border: 1px solid #ddd;
          background: #f9f9f9;
          color: lightgray;
        "
        @click="clickZan(article.id)"
      >
        <i class="el-icon-star-off"></i>
      </div>
      <p
        style="
          font-size: 14px;
          margin-bottom: 3px;
          color: #9b9b9b;
          font-family: DejaVu Sans, serif;
        "
      >
        当前已有{{ article.niceNum }}人点赞 ~~ 🙄🙄
      </p>
    </div>
  </div>
</template>

<script>
import "highlight.js/styles/a11y-dark.css";
import hljs from "highlight.js/lib/common";

export default {
  data() {
    return {
      article: {},
      loading: true,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    hljs.highlightAll();
  },
  methods: {
    getData() {
      let that = this;
      this.$axios
        .get("/blog/getOneArticle", {
          params: that.$route.query,
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.article = resp.data.data;
            that.article.content = that.article.content.replaceAll(
              'code class="',
              'code class="hljs language-'
            );
          } else {
            that.$notify.error({
              title: "错误",
              duration: 0,
              message: resp.data.msg + "请确认路径输入是否有误。",
              offset: 100,
            });
          }
          that.loading = false;
        });
    },
    clickZan(aid) {
      let that = this;
      this.$axios
        .get("/blogApi/addNice", {
          params: {
            aid: aid,
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.article.niceNum = that.article.niceNum + 1;
            that.$notify({
              title: "成功",
              message: resp.data.msg,
              type: "success",
            });
          } else {
            that.$notify.warning({
              title: "警告",
              message: resp.data.msg,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
/* 阅读文章 */
.viewarticle {
  width: 100%;
  padding: 20px;
  box-shadow: rgb(207 207 207) 0px 0px 20px 10px;
  background-color: whitesmoke;
  border-radius: 15px;
  margin-bottom: 29px;
}

.articletitle {
  padding: 0 30px 30px 30px;
}

.viewtitle {
  text-align: center;
  font-weight: 700;
  color: black;
  font-size: 30px;
}

.viewarticle img {
  max-width: 100%;
}

.viewarticle > p {
  font-size: 18px;
  color: #000;
  margin-bottom: 1em;
}

.viewarticle pre {
  font-size: 16px;
  color: #000;
  margin-bottom: 1em;
}

.viewarticle h1,
.viewarticle h2,
.viewarticle h3,
.viewarticle h4,
.viewarticle h5,
.viewarticle h6 {
  margin: 1em 0 0.5em;
}

.zan:hover {
  cursor: pointer;
  background-color: orangered !important;
  color: white !important;
  transition: background-color 0.5s;
}
</style>